
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.inner {
    padding: 78px 0;
    color: #fff;

    @apply mx-auto px-5  flex flex-col-reverse lg:flex-row  items-center  gap-20;
    h2 {
        @apply text-xl  font-bold;
        @include md {
            @apply text-3xl;
        }
    }
    p {
        font-weight: 400;

        font-size: 14px;
        margin-top: 35px;
        line-height: 32px;
        text-align: left;
        max-width: 684px;
        @include md {
            font-size: 18px;
        }
    }
}
.wrapper {
    position: relative;

    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        z-index: -1;

        background-color: #481213;

        background-position: bottom;
    }
}
